<template>


<div class="container">
     <h2 class="pb-2">Lets Chat</h2>
     <p class="ps-1 pb-2">Interested in chatting with me about a development project, or want to learn more about me? Send me a message below or email me at spencer.r.mcdonald@gmail.com and I'll be in touch.</p>
  <form action="mailto:spencer.r.mcdonald@gmail.com" method="get" enctype="text/plain">
  <div class="row">
    <div class="col-sm">
      <label for="fname">First Name</label>
      <input type="text" v-model="fname" name="firstname">
    </div>
    <div class="col-sm">
      <label for="lname">Last Name</label>
      <input type="text" v-model="lname" name="lastname">
    </div>
  </div>
  <div class="col">
     <label for="email">Email</label>
    <input type="text" v-model="email" name="email">
   
  </div>
    <label for="subject">Subject</label>
    <textarea v-model="subject" name="subject" style="height:200px"></textarea>

    <input type="submit" value="Submit">
  </form>
</div>
</template>

<script>

  
</script>


<style>
h2{
    text-align: center;
    font-weight: bold;
    color: #528e86;
}

label{
    color: #528e86;
}

body {font-family: Arial, Helvetica, sans-serif;}
* {box-sizing: border-box;}

input[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #dcdde2;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  
}

input[type=submit] {
  background-color: #528e86;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
}

input[type=submit]:hover {
  background-color: #002f43;
}



.container {
  border-radius: 5px;
  padding: 20px;
}
p{
  
  text-align: center;
}
</style>