<template>
  <div class="home">
    <Contact/>
  </div>
</template>

<script>
// @ is an alias to /src
import Contact from "@/components/Contact.vue";
 export default {
    components: {
      Contact,
    }
  };

</script>
